#exhibitions-wrapper {
    display: flex;
}

#exhibitions-content-container {
    padding: 9px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
}

#exhibitions-content-container ul {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin: 0 auto;
}


@media screen and (max-width: 768px) {
    #exhibitions-wrapper {
        flex-direction: column;
    }
    #exhibitions-content-container {
        height: auto;
    }
  }
  