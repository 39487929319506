@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Open+Sans:wght@300;600&family=Roboto:ital,wght@1,100&display=swap');

#text-wrapper {
    display: flex;
}

#text-wrapper h3 {
    margin-bottom: 1em;
}

#text-content-container {
    padding: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
}

#text-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
}

blockquote {
font-family: 'Roboto', sans-serif;
}


@media screen and (min-width: 768px) {
    #text-content {
        margin-top: 4em;
    }
}
  
@media screen and (max-width: 768px) {
    #text-wrapper {
        flex-direction: column;
    }
    #text-wrapper .side-nav {
        min-height: 5em;
    }
  }
  