@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Open+Sans:wght@300;600&family=Roboto:ital,wght@1,100&display=swap');


#cv-wrapper {
    display: flex;
    height: 100vh;
}

#cv-content-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 9px;
    text-align: left;
}

#cv {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

#cv ul {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.year {
    font-family: Roboto, sans-serif;
    margin-right: 0.5em;
}

#cv-wrapper img {
    width: 15em;
    padding: 9px;
    margin-top: 3em;
}


@media screen and (max-width: 768px) {
    #cv-wrapper {
        flex-direction: column;
    }
    #cv-content-container {
        flex-direction: column;
    }
}

