.main-rubrik a {
    font-size: 3em;
}

.sub-menu {
    margin-bottom: 1em;
}

.sub-menu a {
    font-size: 2em;

}

.sub-menu .nav-link:hover {
    text-decoration: underline;
  }

.nav-link-main:hover {
    color: #969696;
}  