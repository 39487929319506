#site-nav {
    width: 100vw;
}

#site-nav img {
    height: 20em;
}

@media screen and (max-width: 768px) {
    #site-nav img {
        height: 17em;
    }
}
  
@media screen and (max-width: 400px) {
    #site-nav img {
        height: 13em;
    }
}
  