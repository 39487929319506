/* Updated Contact Form Styling */
.contact-form-container {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 89vh;
}

.contact-form {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

textarea {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  height: 150px;
  resize: none;
  outline: none;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.success-message {
  text-align: center;
  font-size: 24px;
  color: #007bff;
  margin-top: 20px;
}


/* Mobile Styling */
@media (max-width: 768px) {
  .contact-form {
    width: 100%;
  }

  .input-field,
  textarea {
    padding: 10px;
    font-size: 14px;
  }

  .submit-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}