* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.page-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000000;
}

.side-nav {
  width: fit-content;
  min-width: 10em;
  height: 100%;
  text-align: left;
  padding: 5px 10px;
  
}

.side-nav ul {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
}

.hannas-link:hover {
  color: hotpink;
}

@media screen and (max-width: 768px) {
  #site-nav img {
    width: auto;
    height: 14em;
  }
  
  .main-rubrik a {
    font-size: 2em; /* Adjust the font size as needed for smaller screens */
  }

  .sub-menu a {
    font-size: 1.5em; /* Adjust the font size for sub-menu items on smaller screens */
  }
}

.slider-wrapper, .axis-horizontal, .carousel-root {
  min-height: 85vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
