#works-wrapper {
    height: 100vh;
    display: flex;
}

#works-nav li{
    margin-top: 3px;
    cursor: pointer;
}

#gallery-container {
    max-height: 100vh;
    max-width: 80%;
    margin: 0 auto;
    padding: 10px;
}

#carousel-container{
    max-height: 75vh;
    max-width: 100%;
    object-fit: scale-down;
}

.gallery-image {
    max-height: 75vh;
    max-width: 100%;
    object-fit: scale-down;

}

.gallery-image img {
    max-height: 75vh;
    max-width: 100%;
    object-fit: scale-down;
}

.active {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    #works-wrapper {
        flex-direction: column;
    }
  }
  